import {version} from '../charting_library';
import { TVChartContainer } from '../components/TVChartContainer/index';
import {Link, useLocation} from 'react-router-dom';

const Home = () => {
  const {search} = useLocation();
  const qp = new URLSearchParams(search);
  const chartType = qp.get('type').charAt(0).toUpperCase() + qp.get('type').slice(1);

  return <div className="chart-component">
    <header className="App-header">
      <h1 className="App-title">
        <Link to="/" style={{color: 'white'}}>HOME</Link> {chartType} <code style={{fontSize: ".5em"}}>TradingView Charting Library Integration {version()}</code>
      </h1>
    </header>
    <TVChartContainer />
  </div>;
};

export default Home;
