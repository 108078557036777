import {Link} from 'react-router-dom';
import {useEffect} from 'react';
import axios from 'axios';

export default function Home() {

  useEffect(() => {
    setInterval(() => {
      axios.get('https://trade-app.nooteboom.nu/').then(res => {
        console.log(`The response from https://trade-app.nooteboom.nu/`, res);
      });
    }, 5000);
  }, []);

  return <div className="home-component">
    <Link to="/chart?type=cryptocurrencies">
      <h2>Cryptocurrencies</h2>
    </Link>
    <Link to="/chart?type=futures">
      <h2>Futures</h2>
    </Link>
  </div>;
};
