import TVHelper from '../tv-helper';

export default function OpenInterest({PineJS, datafeed}) {
  return {
    name: 'Binance Open Interest',
    metainfo: {
      _metainfoVersion: 51,
      id: 'BinanceOpenInterest@tv-basicstudies-1',
      description: 'Binance Open Interest',
      shortDescription: 'Binance Open Interest',
      // is_price_study: false,
      isCustomIndicator: true,
      plots: [
        {
          id: 'plot_0',
          type: 'line',
        },
      ],
      palettes: {
        paletteId1: {
          colors: {
            0: {
              name: 'First color',
            },
            1: {
              name: 'Second color',
            },
          },
        },
      },
      defaults: {
        palettes: {
          paletteId1: {
            colors: {
              0: {
                color: 'red',
                width: 1,
                style: 0,
              },
              1: {
                color: 'blue',
                width: 3,
                style: 1,
              },
            },
          },
        },
        styles: {
          plot_0: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: 2,
            trackPrice: true,
            color: 'blue',
          },
        },
        precision: 4,
        inputs: {},
      },
      styles: {
        plot_0: {
          title: 'Equity value',
          histogramBase: 0,
        },
      },
      inputs: [],
      format: {
        type: 'volume',
        precision: 4,
      },
    },
    constructor: function() {
      this.main = function(context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        let value = datafeed.data.oi_hashtable[context.symbol.time]?.openInterest;

        if (!value) {
          console.log('DEBUG STUDY main symbol', context.symbol.time);
          value = datafeed.data.oi.at(-1)?.openInterest;
        }

        value = parseFloat(value || NaN);

        return [value];
      };
    },
  };
}
