import binanceWS from './socketClient';
import axios from 'axios';
import moment from 'moment';
import TVHelper from '../../tv-helper';

export default class BinanceBasicAPI {

  static baseUrl;
  static baseUrlApi;
  static streamBaseUrl;
  static EVENTS = {
    ON_GET_BARS: 'on_get_bars',
    ON_CHANGE_RESOLUTION: 'on_change_resolution',
  };

  CONFIGS = {
    oi_update_interval: 500,
  };
  tmp = {
    oi_update_timestamp: 0,
  };
  symbols = [];
  events = {
    on_get_bars: [],
    on_change_resolution: [],
  };
  data = {
    klines: [],
    oi: [],
    oi_hashtable: {},
    liquidation: [],
    liquidation_hashtable: {},
  };
  currentResolution;
  to;

  /**
   * @param options
   * @param {string} options.service
   */
  constructor(options) {
    if (!this.constructor.baseUrl || !this.constructor.baseUrlApi) {
      throw new Error(`Define a Binance service that you want to use. Possible values: spot, futures.`);
    }

    this.http = axios.create({baseURL: this.constructor.baseUrl});
    this.httpApi = axios.create({baseURL: this.constructor.baseUrlApi});
    this.httpServer = axios.create({baseURL: process.env.REACT_APP_SERVER_BASE_URL});
    this.debug = options.debug || false;
    this.ws = new binanceWS(this.constructor.streamBaseUrl);

    this.on(this.constructor.EVENTS.ON_CHANGE_RESOLUTION, () => {
      console.log(`DEBUG FUTURES EVENTS ON_CHANGE_RESOLUTION`);
      this.data.klines = [];
      this.data.oi = [];
      this.data.oi_hashtable = {};
      this.data.liquidation = [];
      this.data.liquidation_hashtable = {};
      /*this.data = {
        klines: [],
        oi: [],
        oi_hashtable: {},
        liquidation: [],
        liquidation_hashtable: {},
      };*/
    });
    this.on(this.constructor.EVENTS.ON_GET_BARS, ({klines, oi, liquidation}) => {
      console.log(`DEBUG FUTURES EVENTS ON_GET_BARS`);
      this.data.klines = this.data.klines.concat(klines);
      this.data.oi = this.data.oi.concat(oi);
      // this.data.oi_hashtable = {};
      this.data.oi.forEach(i => {
        this.data.oi_hashtable[i.time] = i;
      });
      this.data.liquidation = this.data.liquidation.concat(liquidation);
      // this.data.liquidation_hashtable = {};
      this.data.liquidation.forEach(i => {
        this.data.liquidation_hashtable[i.t] = i;
      });
    });
  }

  async binanceSymbols() {
    return (await this.httpApi.get(`/exchangeInfo`)).data.symbols;
  }

  /**
   * Return klines with all data
   * @param symbol
   * @param interval
   * @param startTime
   * @param endTime
   * @param limit
   * @returns {Promise<{oi: *[], klines: *}>}
   */
  async klinesFull(symbol, interval, startTime, endTime, limit) {
    const klines = await this.klines(symbol, interval, startTime, endTime, limit);
    this.debug && console.log('DEBUG binanceKlines', klines);
    const oi = [];

    return {klines, oi};
  }

  /**
   * Return only klines data
   *
   * @param symbol
   * @param interval
   * @param startTime
   * @param endTime
   * @param limit
   * @returns {Promise<any>}
   */
  async klines(symbol, interval, startTime, endTime, limit) {
    return (await this.httpApi.get(`/klines`, {
      params: {
        symbol,
        interval,
        startTime: startTime ? startTime : undefined,
        endTime: endTime ? endTime : undefined,
        limit: limit ? limit : undefined,
      },
    })).data;
  }

  // chart specific functions below, impt that their function names stay same
  onReady(callback) {
    this.debug && console.info('DEBUG', 'onReady');

    this.binanceSymbols().then((symbols) => {
      console.log('DEBUG API onReady symbols', symbols);
      this.symbols = symbols;
      callback({
        supports_marks: false,
        supports_timescale_marks: false,
        supports_time: true,
        supported_resolutions: [
          '1', '5', '15', '30', '60', '120', '240', '360', '720', '1D', '1W', '1M',
        ],
      });
    }).catch(err => {
      console.error('Error onReady');
      console.error(err);
    });
  }

  searchSymbols(userInput, exchange, symbolType, onResult) {
    this.debug && console.info('DEBUG API searchSymbols');
    userInput = userInput.toUpperCase();
    onResult(
        this.symbols.filter((symbol) => {
          return symbol.symbol.indexOf(userInput) >= 0;
        }).map((symbol) => {
          return {
            symbol: symbol.symbol,
            full_name: symbol.symbol,
            description: symbol.baseAsset + ' / ' + symbol.quoteAsset,
            ticker: symbol.symbol,
            exchange: 'Binance',
            type: 'crypto',
          };
        }),
    );
  }

  resolveSymbol(symbolName, onSymbolResolvedCallback, onResolveErrorCallback) {
    this.debug && console.info('DEBUG API resolveSymbol', symbolName);

    const comps = symbolName.split(':');
    symbolName = (comps.length > 1 ? comps[1] : symbolName).toUpperCase();

    function pricescale(symbol) {
      for (let filter of symbol.filters) {
        if (filter.filterType == 'PRICE_FILTER') {
          return Math.round(1 / parseFloat(filter.tickSize));
        }
      }
      return 1;
    }

    this.debug && console.log('DEBUG API resolveSymbol symbols', this.symbols);

    for (let symbol of this.symbols) {
      if (symbol.symbol === symbolName) {
        setTimeout(() => {
          onSymbolResolvedCallback({
            name: symbol.symbol,
            description: symbol.baseAsset + ' / ' + symbol.quoteAsset,
            ticker: symbol.symbol,
            exchange: 'Binance',
            listed_exchange: 'Binance',
            type: 'crypto',
            session: '24x7',
            minmov: 1,
            pricescale: pricescale(symbol),
            // timezone: 'UTC',
            has_intraday: true,
            has_daily: true,
            has_weekly_and_monthly: true,
            currency_code: symbol.quoteAsset,
          });
        }, 0);
        return;
      }
    }

    // minmov/pricescale will give the value of decimal places that will be shown on y-axis of the chart
    //
    onResolveErrorCallback('not found');
  }

  getBars(symbolInfo, resolution, periodParams, onResult, onError) {
    this.debug && console.log('DEBUG API getBars periodParams', periodParams, resolution);
    let from = periodParams.from;
    let to = Math.floor(TVHelper.to(resolution, periodParams.to * 1000) / 1000);
    const interval = this.ws.tvIntervals[resolution];

    if (!interval) {
      onError('Invalid interval');
    }

    // Detect changing of the resolution
    if (this.currentResolution !== resolution) {
      this.fireEvent(this.constructor.EVENTS.ON_CHANGE_RESOLUTION);
    }

    this.currentResolution = resolution;
    this.to = to;

    let totalKlines = [];
    let totalOI = [];
    let totalLiquidation = [];
    const kLinesLimit = 500;
    const finishKlines = () => {
      if (totalKlines.length === 0) {
        onResult([], {noData: true});
      } else {
        let historyCBArray = totalKlines.map(kline => ({
          time: kline[0],
          open: parseFloat(kline[1]),
          high: parseFloat(kline[2]),
          low: parseFloat(kline[3]),
          close: parseFloat(kline[4]),
          volume: parseFloat(kline[5]),
        }));
        this.fireEvent(this.constructor.EVENTS.ON_GET_BARS, {klines: historyCBArray, oi: totalOI, liquidation: totalLiquidation});
        onResult(historyCBArray, {noData: false});
      }
    };

    const getKlines = async (from, to) => {
      try {
        const data = await this.klinesFull(symbolInfo.name, interval, from, to, kLinesLimit);
        console.log('DEBUG getKlines', data);
        let {klines, oi, liquidation} = data;
        totalKlines = totalKlines.concat(klines);
        totalOI = totalOI.concat(oi);
        totalLiquidation = totalLiquidation.concat(liquidation);
        if (klines.length === kLinesLimit) {
          from = klines[klines.length - 1][0] + 1;
          await getKlines(from, to);
        } else {
          finishKlines();
        }
      } catch (e) {
        console.error(e);
        onError(`Error in 'getKlines' func`);
      }
    };

    from *= 1000;
    to *= 1000;

    getKlines(from, to).then(klines => {
    }).catch(ex => {
      this.debug && console.error('DEBUG API getBars getKlines klines', ex);
    });
  }

  subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
    this.debug && console.info('DEBUG API subscribeBars');
    this.ws.subscribeOnStream(
        symbolInfo,
        resolution,
        (data) => {
          // Check the resolution and timeframes to ensure that different resolutions are not mixed
          if (this.currentResolution === resolution && data.time === this.to) {
            //region Update OI
            if ((new Date).getTime() - this.tmp.oi_update_timestamp >= this.CONFIGS.oi_update_interval) {
              this.openInterest(symbolInfo.name).then(oi => {
                this.data.oi.push(oi);
              });
              this.tmp.oi_update_timestamp = (new Date).getTime();
            }
            //endregion

            onRealtimeCallback(data);
          }
        },
        subscriberUID,
        onResetCacheNeededCallback,
    );
  }

  unsubscribeBars(subscriberUID) {
    this.debug && console.info('DEBUG API unsubscribeBars', subscriberUID);
    this.ws.unsubscribeFromStream(subscriberUID);
  }

  getMarks(symbolInfo, startDate, endDate, onDataCallback, resolution) {
    this.debug && console.log('DEBUG API getMarks');
  }

  getTimescaleMarks(symbolInfo, startDate, endDate, onDataCallback, resolution) {
    this.debug && console.log('DEBUG API getTimescaleMarks');
  }

  getServerTime() {
    this.debug && console.log('DEBUG API getServerTime');
  }

  getVolumeProfileResolutionForPeriod() {
    this.debug && console.log('DEBUG API getVolumeProfileResolutionForPeriod');
  }

  getQuotes() {
    this.debug && console.log('DEBUG API getQuotes');
  }

  subscribeQuotes() {
    this.debug && console.log('DEBUG API subscribeQuotes');
  }

  unsubscribeQuotes() {
    this.debug && console.log('DEBUG API unsubscribeQuotes');
  }

  subscribeDepth() {
    this.debug && console.log('DEBUG API subscribeDepth');
  }

  unsubscribeDepth() {
    this.debug && console.log('DEBUG API unsubscribeDepth');
  }

  // END of DataFeed realization

  /**
   * Retrieve the latest Open Interest (OI) value and add it to the list of OI values
   *
   * @param {string} symbolName
   */
  openInterest(symbolName) {
  }

  on(eventName, cb) {
    if (!this.events[eventName]) {
      throw new Error(`"${eventName}" event does not exist.`);
    }

    this.events[eventName].push(cb);
  }

  fireEvent(eventName, data) {
    if (!this.events[eventName]) {
      throw new Error(`"${eventName}" event does not exist.`);
    }

    this.events[eventName].forEach(cb => {
      cb(data);
    });
  }

}
