import TVHelper from '../tv-helper';
import * as _ from 'lodash';

export default function Liquidations({PineJS, datafeed}) {
  return {
    name: 'Liquidations',
    metainfo: {
      _metainfoVersion: 51,
      id: 'liquidations@tv-basicstudies-1',
      name: 'Liquidations',
      description: 'Liquidations',
      shortDescription: 'Liquidations',
      isCustomIndicator: true,
      // whether the study should appear on the main series pane.
      is_price_study: false,
      // whether the study price scale should be the same as the main series one.
      linkedToSeries: true,
      format: {
        type: 'price',
        precision: 2,
      },
      plots: [
        {
          id: 'plot_0',
          type: 'line',
        },
        {
          id: 'plot_0_colorer',
          type: 'colorer',
          target: 'plot_0',
          palette: 'plot_0_palette_0',
        },
        {
          id: 'plot_1',
          type: 'line',
        },
        {
          id: 'plot_1_colorer',
          type: 'colorer',
          target: 'plot_1',
          palette: 'plot_1_palette_1',
        },
      ],

      palettes: {
        plot_0_palette_0: {
          colors: {
            0: {
              name: 'Above color',
            },
            1: {
              name: 'Below color',
            },
          },
        },
      },

      defaults: {
        palettes: {
          plot_0_palette_0: {
            colors: {
              0: {
                color: '#264f17',
                width: 1,
                style: 0,
              },
              1: {
                color: '#6a1d1c',
                width: 1,
                style: 0,
              },
            },
          },
        },

        styles: {
          plot_0: {
            linestyle: 0,
            visible: true,
            linewidth: 2,
            plottype: 5,
            trackPrice: false,
            color: '#264f17',
          },
          plot_1: {
            linestyle: 0,
            visible: true,
            linewidth: 2,
            plottype: 5,
            trackPrice: false,
            color: '#6a1d1c',
          },
        },

        precision: 2,
        inputs: {},
      },
      styles: {
        plot_0: {
          title: 'Plot',
          histogramBase: 0,
          style: 5,
        },
        plot_1: {
          title: 'Plot',
          histogramBase: 0,
          style: 5,
        },
      },
      inputs: [],
    },
    constructor: function() {
      this.main = function(context, inputCallback) {
        this._context = context;
        this._input = inputCallback;


        this.l = parseFloat(datafeed.data.liquidation_hashtable[context.symbol.time]?.l);
        this.l = _.isNumber(this.l) && !isNaN(this.l) ? this.l : 0;
        this.s = parseFloat(datafeed.data.liquidation_hashtable[context.symbol.time]?.s);
        this.s = _.isNumber(this.s) && !isNaN(this.s) ? this.s * -1 : 0;

        return [this.l, 0, this.s, 1];
      };
    },
  };
}
