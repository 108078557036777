import binanceWS from './socketClient';
import BinanceBasicAPI from './basicApi';

export default class BinanceSpotApi extends BinanceBasicAPI {

  static baseUrl = 'https://api.binance.com';
  static baseUrlApi = 'https://api.binance.com/api/v3';
  static streamBaseUrl = 'wss://stream.binance.com:9443/ws';

}
