export default class SocketClient {

  debug = false;

  constructor(base_url, path) {
    this.baseUrl = base_url;
    this.tvIntervals = {
      '1': '1m',
      '3': '3m',
      '5': '5m',
      '15': '15m',
      '30': '30m',
      '60': '1h',
      '120': '2h',
      '240': '4h',
      '360': '6h',
      '480': '8h',
      '720': '12h',
      'D': '1d',
      '1D': '1d',
      '3D': '3d',
      'W': '1w',
      '1W': '1w',
      'M': '1M',
      '1M': '1M',
    };
    this.streams = {};
    this._createSocket();
  }

  _createSocket() {
    this._ws = new WebSocket(this.baseUrl);
    this._ws.onopen = (e) => {
      this.debug && console.info(`Binance WS Open`);
      localStorage.setItem('wsStatus', 1);
    };

    this._ws.onclose = () => {
      console.warn('Binance WS Closed');
      localStorage.setItem('wsStatus', 0);
    };

    this._ws.onerror = (err) => {
      console.warn('WS Error', err);
      localStorage.setItem('wsStatus', 0);
    };

    this._ws.onmessage = (msg) => {
      if (!msg?.data) return;
      let sData = JSON.parse(msg.data);
      try {
        if (sData && sData.k) {
          let {s, E} = sData;
          this.debug && console.log('DEBUG WS onmessage', sData);
          let {o, h, l, v, c, T, t} = sData.k;
          // Update data
          let lastSocketData = {
            time: t,
            close: parseFloat(c),
            open: parseFloat(o),
            high: parseFloat(h),
            low: parseFloat(l),
            volume: parseFloat(v),
            closeTime: T,
            openTime: t,
          };

          for (let streamID in this.streams) {
            this.streams[streamID].data = lastSocketData;
            this.streams[streamID].listener(lastSocketData);
          }
        }
      } catch (e) {
        console.error(e);
      }

    };
  }

  subscribeOnStream(symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback, lastDailyBar) {
    console.log('DEBUG WS subscribeOnStream', {
      subscribeUID,
      resolution: this.tvIntervals[resolution],
    });
    try {
      let paramStr = `${symbolInfo.name.toLowerCase()}@kline_${this.tvIntervals[resolution]}`;
      const obj = {
        method: 'SUBSCRIBE',
        params: [
          paramStr,
        ],
        id: 1,
      };
      if (this._ws.readyState === 1) {
        this._ws.send(JSON.stringify(obj));
        //this.streams[symbolInfo.name] = {  //register multiple streams in streams object
        this.streams[subscribeUID] = {  //register multiple streams in streams object
          paramStr,
          listener: onRealtimeCallback,
        };
      }
    } catch (e) {
      console.error(e);
    }
  }

  unsubscribeFromStream(subscriberUID) {
    console.log('DEBUG WS unsubscribeFromStream', subscriberUID);
    try {
      let streamID = subscriberUID.split('_')[0];
      streamID = subscriberUID;
      const obj = {
        method: 'UNSUBSCRIBE',
        params: [
          this.streams[streamID].paramStr,
        ],
        id: 1,
      };
      delete this.streams[streamID];
      if (this._ws.readyState === 1) {
        this._ws.send(JSON.stringify(obj));
      }
    } catch (e) {
      console.error(e);
    }
  }
}
