import moment from 'moment/moment';

export default class TVHelper {

  static RESOLUTION = {
    'S': 1,
    'm': 60,
    'h': 60 * 60,
    'H': 60 * 60,
    'd': 24 * 60 * 60,
    'D': 24 * 60 * 60,
    'w': 7 * 24 * 60 * 60,
    'W': 7 * 24 * 60 * 60,
    // Attention! Months use the average days in one month
    'M': 30.4166665416 * 24 * 60 * 60,
  };

  /**
   * Translate periods.
   *
   * Attention! Months use the average days in one month
   *
   * https://www.tradingview.com/charting-library-docs/latest/core_concepts/Resolution
   *
   * @param resolution
   * @returns {number}
   */
  static translateResolutionSeconds(resolution) {
    resolution = resolution.trim();

    // If only a number "x" is supplied then this number is given in minutes
    if (!/[a-zA-Z]$/gm.test(resolution)) {
      return parseInt(resolution) * this.RESOLUTION.m;
    }

    let extent = this.RESOLUTION[resolution.at(-1)];

    return parseInt(parseInt(resolution.substring(0, resolution.length - 1)) * extent);
  }

  /**
   * Return resolution type
   *
   * E.g. 15m -> m; 1h -> h
   *
   * @param resolution
   * @returns {*|string}
   */
  static resolutionType(resolution) {
    resolution = resolution.trim();

    // If only a number "x" is supplied then this number is given in minutes
    if (!/[a-zA-Z]$/gm.test(resolution)) {
      return 'm'
    }

    let type = this.RESOLUTION[resolution.at(-1)];

    if (Object.keys(this.RESOLUTION).includes(type)) {
      return type;
    }
  }

  /**
   * Linear intERPolation
   *
   * @param a
   * @param b
   * @param alpha
   * @returns {*}
   */
  static lerp(a, b, alpha) {
    return a + alpha * (b - a);
  }

  /**
   * Adjust a given or current timestamp based on the incoming resolution
   *
   * @param resolution
   * @param timestamp
   * @returns {number}
   */
  static to(resolution, timestamp) {
    let to = moment.utc();

    if (timestamp) {
      to = moment.utc(to);
    }

    let resolutionValue = parseInt(resolution);
    let resolutionDimension = /[a-zA-Z]/.exec(resolution)?.at(0).toLowerCase();

    // > 60 in other words > 1 hour
    if (!resolutionDimension && resolutionValue < 60) {
      // Reset time to 00:00
      to.startOf('minute');
      let minutes = resolutionValue;
      let minutesDiff = to.minutes() % minutes;
      to.subtract(minutesDiff, 'minutes');
    } else if (!resolutionDimension && resolutionValue >= 60) {
      // Reset time to 00:00:00
      to.startOf('hour');
      // Calculate difference in hours and subtract it
      let hours = resolutionValue / 60;
      let hoursDiff = to.hours() % hours;
      to.subtract(hoursDiff, 'hours');
    } else if (resolutionDimension === 'd' && resolutionValue === 1) {
      to.startOf('day');
    } else if (resolutionDimension === 'w' && resolutionValue === 1) {
      to.startOf('week');
    } else if (resolutionDimension === 'm' && resolutionValue === 1) {
      to.startOf('month');
    }

    return to.valueOf();
  }

}
