import BinanceBasicAPI from './basicApi';
import TVHelper from '../../tv-helper';
import * as moment from 'moment';

export default class BinanceFutureAPI extends BinanceBasicAPI {

  static baseUrl = 'https://fapi.binance.com';
  static baseUrlApi = 'https://fapi.binance.com/fapi/v1';
  static streamBaseUrl = 'wss://fstream.binance.com/ws';

  async klinesFull(symbol, interval, startTime, endTime, limit) {
    let wait = [];
    let optionalWait = [];
    let klines, oi, liquidation = [];
    wait.push(this.klines(symbol, interval, startTime, endTime, limit).then(res => klines = res));
    // OI
    wait.push(this.openInterestHist({symbol, interval, startTime, endTime, limit}).then(res => oi = res));
    // Liquidation
    try {
      wait.push(this.liquidationHist({symbol, interval, startTime, endTime, limit}).then(res => liquidation = res));
    } catch (e) {
      console.error(e);
    }

    for (let a in wait) {
      try {
        await wait[a];
      } catch (e) {
        console.error(e);
      }
    }

    let oiHashtable = {};
    oi.forEach(i => {
      oiHashtable[i.timestamp] = i;
    });
    console.log('DEV klines', klines);

    const klinesTimeframe = klines.length > 1 ? klines[1][0] - klines[0][0] : undefined;
    const oiTimeframe = oi.length > 1 ? oi[1].time - oi[0].time : undefined;

    //@todo Fix with months
    // Months have different number of days
    if (klinesTimeframe > 0 && oiTimeframe > 0 && klinesTimeframe < oiTimeframe) {
      const klinesTimeframe = klines[1][0] - klines[0][0];
      console.log(`DEBUG FUTURES processing klinesTimeframe `, klinesTimeframe, oiTimeframe, oiTimeframe / klinesTimeframe);
      console.log(`DEBUG FUTURES processing klines len before `, klines.length);
      console.log(`DEBUG FUTURES processing OI len before `, oi.length);

      oi.forEach((_oi, i) => {
        const oiTimestamp = _oi.time;
        const [oi1, oi2] = [_oi, oi[i + 1]];

        if (!(oi1 && oi2)) {
          return;
        }

        const times = parseInt(oiTimeframe / klinesTimeframe);

        for (let t = 0; t < times; t++) {
          let time = oi1.time + klinesTimeframe * t;
          oiHashtable[time] = {
            ...oi1,
            time,
            openInterest: TVHelper.lerp(oi1.openInterest, oi2.openInterest, 1 / times * t),
          };
        }
      });

      oi = Object.values(oiHashtable).sort((a, b) => {
        if (a.time < b.time) {
          return -1;
        }
        if (b.time > a.time) {
          return 1;
        }

        return 0;
      });

      console.log(`DEBUG FUTURES processing klines len after `, klines.length);
      console.log(`DEBUG FUTURES processing OI len after `, oi.length);
      console.log(`DEBUG FUTURES processing OI after `, JSON.stringify(oi));
    }

    return {klines, oi, liquidation};
  }

  /**
   * Retrieve the Open Interest history
   *
   * @param symbol
   * @param interval
   * @param startTime
   * @param endTime
   * @param limit
   * @returns {Promise<any>}
   */
  async openInterestHist({symbol, interval, startTime, endTime, limit}) {
    // Find the closest time interval
    let availablePeriods = {};
    ['5m', '15m', '30m', '1h', '2h', '4h', '6h', '12h', '1d'].forEach(i => {
      availablePeriods[TVHelper.translateResolutionSeconds(i)] = i;
    });
    let intervalS = TVHelper.translateResolutionSeconds(interval);
    let period = Object.keys(availablePeriods).reduce((prev, curr) => {
      return Math.abs(curr - intervalS) < Math.abs(prev - intervalS) ? curr : prev;
    });
    period = availablePeriods[period];

    // OI Hist is restricted to 30 days of history
    if (moment().diff(moment.unix(endTime / 1000), 'days') > 30) {
      return [];
    }

    if (moment().diff(moment.unix(startTime / 1000), 'days') > 30) {
      startTime = moment().subtract(30, 'days').valueOf()
    }

    return (await this.http.get(`/futures/data/openInterestHist`, {
      params: {
        symbol,
        period,
        startTime: startTime ? startTime : undefined,
        endTime: endTime ? endTime : undefined,
        limit,
      },
    })).data.map(i => ({
      openInterest: parseFloat(i.sumOpenInterest),
      symbol: i.symbol,
      time: i.timestamp,
    }));
  }

  async openInterest(symbolName) {
    let res = (await this.httpApi.get(`/openInterest`, {
      params: {
        symbol: symbolName,
      },
    })).data;
    res.openInterest = parseFloat(res.openInterest);

    return res;
  }

  async liquidationHist({symbol, interval, startTime, endTime, limit}) {
    return (await this.httpServer(`/binance/liquidation`, {
      params: {
        symbol,
        interval,
        startTime: startTime ? startTime : undefined,
        endTime: endTime ? endTime : undefined,
        limit,
      },
    })).data.map(item => {
      return {
        t: item[0],
        l: item[1],
        s: item[2],
      };
    });
  }

}
