import React, {useEffect, useRef} from 'react';
import './index.css';
import {widget} from '../../charting_library';
import {useLocation} from 'react-router-dom';
import BinanceSpotApi from '../../services/datafeeds/binance/spotApi';
import BinanceFutureAPI from '../../services/datafeeds/binance/futureApi';
import BinanceBasicAPI from '../../services/datafeeds/binance/basicApi';
import OpenInterest from '../../services/indicators/open-interest';
import Liquidations from '../../services/indicators/liquidations';
import axios from 'axios';

function getLanguageFromURL() {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export const TVChartContainer = () => {
  const {search} = useLocation();
  const qp = new URLSearchParams(search);
  const chartContainerRef = useRef();

  const httpServer = axios.create({baseURL: process.env.REACT_APP_SERVER_BASE_URL});
  let serviceClass;

  switch (qp.get('type')) {
    case 'cryptocurrencies':
      serviceClass = BinanceSpotApi;
      break;
    case 'futures':
      serviceClass = BinanceFutureAPI;
      break;
  }

  /** @type {BinanceBasicAPI} */
  let datafeed = new serviceClass({debug: true});
  const defaultProps = {
    symbol: 'ETHUSDT',
    // BEWARE: no trailing slash is expected in feed URL
    datafeed,
    interval: '1H',
    library_path: '/charting_library/',
    locale: 'en',
    debug: true,
    fullscreen: false,
    theme: 'light',
    allow_symbol_change: true,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    autosize: true,
    time_frames: [
      {text: '1H', resolution: '1', description: '1 Hour', title: '1H'},
      {text: '4H', resolution: '1', description: '4 Hours', title: '4H'},
      {text: '8H', resolution: '1', description: '8 Hours', title: '8H'},
      {text: '12H', resolution: '1', description: '12 Hours', title: '12H'},
      {text: '1D', resolution: '1', description: '1 Day', title: '1D'},
      {text: '3D', resolution: '5', description: '3 Days', title: '3D'},
      {text: '1W', resolution: '30', description: '1 Week', title: '1W'},
      {text: '1M', resolution: '30', description: '1 Month', title: '1M'},
      {text: '3M', resolution: '60', description: '3 Months', title: '3M'},
      {text: '6M', resolution: '120', description: '6 Months', title: '6M'},
      {text: '1Y', resolution: '1W', description: '1 Year', title: '1Y'},
    ],
    favorites: {
      indicators: ['Binance Open Interest', 'Liquidations'],
    },
    custom_indicators_getter: function(PineJS) {
      return Promise.resolve([
        OpenInterest({PineJS, datafeed}),
        Liquidations({PineJS, datafeed}),
      ]);
    },
  };

  useEffect(() => {
    let tvWidget;

    (async () => {
      const savedChartLayout = (await httpServer.get('/client/chart-layout')).data;
      const savedChart = savedChartLayout?.layout?.charts?.at(0);

      tvWidget = new widget({
        ...defaultProps,
        // Container becomes available during the initialization
        container: chartContainerRef.current,
        symbol: savedChart?.chart?.symbol || defaultProps.symbol,
        interval: savedChart?.chart?.resolution || defaultProps.interval,
        timezone: savedChart?.chart.timezone || defaultProps.timezone,
      });

      tvWidget.onChartReady(() => {
        const chart = tvWidget.activeChart();

        //region Recover saved studies layout
        (savedChart?.studies || []).forEach(study => {
          // Skip some studies
          if (['volume'].includes(study.name.toLowerCase())) return;

          try {
            chart.createStudy(study.name);
          } catch (err) {
            if (!err.message.toLowerCase().includes('unexpected study')) throw new Error(err);
            else console.warn(err.message);
          }
        });
        //endregion

        tvWidget.headerReady().then(() => {
          //region Save layout
          const button = tvWidget.createButton({align: 'left'});
          button.innerText = 'Save layout';
          button.addEventListener('click', () => tvWidget.save(saveObject => {
            let layout = {
              charts: [],
            };

            for (let i = 0; i < tvWidget.chartsCount(); i++) {
              let chart = tvWidget.chart(i);
              let chartLayout = {
                chart: {
                  id: chart.id(),
                  symbol: chart.symbol(),
                  resolution: chart.resolution(),
                  timezone: chart.getTimezone(),
                },
                studies: chart.getAllStudies(),
              };
              layout.charts.push(chartLayout);
            }

            console.log('DEBUG SAVE active layout', layout);

            httpServer.put('/client/chart-layout', layout).then(() => {
              tvWidget.showNoticeDialog({
                title: 'Notification',
                body: 'Chart layout is saved',
                callback: () => console.log('Noticed!'),
              });
            });
          }));
          //endregion
        });
      });
    })().then();

    return () => {
      if (tvWidget) {
        tvWidget.remove();
      }
    };
  });

  return (
      <div
          ref={chartContainerRef}
          className={'TVChartContainer'}
      />
  );
};
