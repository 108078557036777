import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Layout from './pages/layout';
import NotFound from './pages/not-found';
import Home from './pages/home';
import Chart from './pages/chart';

export default function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout/>}>
            <Route index element={<Home/>}/>
            <Route path="/chart" element={<Chart/>}/>
            <Route path="*" element={<NotFound/>}/>
          </Route>
        </Routes>
      </BrowserRouter>
  );
}
